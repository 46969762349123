<template>
  <div>
    <v-alert type="info" text prominent class="mb-4">
      <v-container>
        <v-row>
          <v-col>
            {{ statistics.confirmedCount }} von
            {{ statistics.needToConfirmCount }} Schüler*innen haben die Wahl
            ihrer myGymer-Angebote bestätigt.
            <v-progress-linear :value="percentage" color="info"
              >{{ percentage }}%</v-progress-linear
            >
          </v-col>
          <v-col cols="auto">
            <ReportButton
              depressed
              resource="report/planningoverview"
              color="success"
              :parameters="{}"
              >Planungsvorlage</ReportButton
            >
          </v-col>
        </v-row>
      </v-container>
    </v-alert>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      hide-default-footer
      :items-per-page="-1"
    >
    </v-data-table>
  </div>
</template>

<script>
import ReportButton from '@/components/ReportButton';
export default {
  name: 'OptionalAvailability',
  components: { ReportButton },
  props: ['search'],
  data() {
    return {
      statistics: {},
      items: [],
      headers: [
        {
          text: 'Fach',
          value: 'subject.description',
        },
        { text: 'Stufe', value: 'gradeNow.description' },
        { text: 'Teilnehmer', value: 'countNow' },
        { text: 'Stufe', value: 'gradeNext.description' },
        { text: 'Anmeldungen', value: 'countNext' },
      ],
    };
  },
  computed: {
    percentage() {
      return parseInt(
        (100 * this.statistics.confirmedCount) /
          this.statistics.needToConfirmCount
      );
    },
  },
  async created() {
    this.statistics = await this.apiList({ resource: 'optional/statistics' });
    this.items = this.statistics.optionalSubjects.sort((a, b) => {
      if (a.subject.description > b.subject.description) {
        return 1;
      }
      if (a.subject.description < b.subject.description) {
        return -1;
      }
      if (a.gradeNow && b.gradeNow && a.gradeNow.id > b.gradeNow.id) {
        return -1;
      }
      if (a.gradeNow && b.gradeNow && a.gradeNow.id < b.gradeNow.id) {
        return 1;
      }
      if (a.gradeNext && b.gradeNext && a.gradeNext.id > b.gradeNext.id) {
        return -1;
      }
      if (a.gradeNext && b.gradeNext && a.gradeNext.id < b.gradeNext.id) {
        return 1;
      }
    });
  },
};
</script>
